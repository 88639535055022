import { mapActions, mapState } from 'vuex'
import  { myMixin } from '../../../../mixins';

export default {
  mixins: [myMixin],
  data: () => ({
        loadingProgress: false,
        message: '',
        error: false,
        selectedItem: null,
        selectedTeacher: null,
        showImage: false,
        dialog: false,
        onlyVariation: false,
        filters: [0, 1, 2, 3],
        icons: ['mdi-check', 'mdi-close-circle-outline'],
        headers: [],
        items: [],
        periodActual: null,
        summary: {no_work_load: 0, reducing: 0, keeping: 0, increasing: 0, with_variation: 0 },
        search: null,
        tags: [
            { id: 0, name: 'Zerado',     value:'no_work_load',  color: 'amber',     icon: 'mdi-checkbox-blank-circle-outline'},
            { id: 1, name: 'Reduzindo',  value:'reducing',      color: 'error',     icon: 'mdi-stairs-down', },
            { id: 2, name: 'Mantendo',   value:'keeping',       color: 'primary',   icon: 'mdi-minus-circle-outline'},
            { id: 3, name: 'Aumentando', value:'increasing',    color: 'success',   icon: 'mdi-stairs-up'},
            
          ],
    }),
    
    watch: {
        
    },

    computed: {
      ...mapState('auth', ['acesso']),
    
    itemsFiltered(){
        
        var itemsTemp = [];
        
        if(this.filters.length == 4){
            itemsTemp = this.items;
        }else if(this.filters.length == 0){
            itemsTemp = [];
        }else{

            if(this.filters.includes(0)){
                itemsTemp = this.getItemsByIndex(0);
            }

            if(this.filters.includes(1)){
                var finds = this.getItemsByIndex(1);
                finds.forEach(f => {
                    if(!itemsTemp.includes(f)){
                        itemsTemp.push(f);
                    }
                });
            }

            if(this.filters.includes(2)){
                var finds = this.getItemsByIndex(2);
                finds.forEach(f => {
                    if(!itemsTemp.includes(f)){
                        itemsTemp.push(f);
                    }
                });
            }

            if(this.filters.includes(3)){
                var finds = this.getItemsByIndex(3);
                finds.forEach(f => {
                    if(!itemsTemp.includes(f)){
                        itemsTemp.push(f);
                    }
                });
            }
        }

        if(this.onlyVariation){
            itemsTemp = itemsTemp.filter(i => i.variation);
        }

        return itemsTemp;
        
      }
    },
    mounted(){
       this.getData();
    },
    created() {
      
    },
    methods: {
      ...mapActions('controlPanel', ['ActionWorkLoadBalance']),
        
      getData(){
      
        this.loadingProgress = true;
        this.items = [];

        this.ActionWorkLoadBalance()
            .then((res) => {
                this.loadHeaders(res.data.headers);
                this.summary = res.data.summary;
                this.periodActual = res.data.period_actual;
                this.items = res.data.items;
                this.items.sort((a, b) => {
                    return a.teacher.name.toString().localeCompare(b.teacher.name);
                });
            })
            .finally(() => {
                this.loadingProgress = false;
            })
    },

    loadHeaders(headers){
        
        this.headers = [];

        headers.sort(function(a, b){
            return a.toString().localeCompare(b);
        })
        
        this.headers.push({ text: 'Professor',  value: 'teacher'});
        this.headers.push({ text: 'Titulação',  value: 'teacher.titling.name'});
        this.headers.push({ text: 'Regime',     value: 'teacher.workRegime.initials'});
        
        headers.forEach(h => {
            this.headers.push({
                text: h, value: h, align: 'center', period: true
            });
        });

        if(this.acesso.instituicao.calculate_variation){
            this.headers.push({ text: 'Resilição', value: 'variation', align: 'center'});
        }
        this.headers.push({ text: 'Saldo Atual', value: 'balance', align: 'center'});
        this.headers.push({ text: 'Status', value: 'status', align: 'center',  sortable: false});
        this.headers.push({ text: 'Ações', value: 'actions', align: 'center', sortable: false});

    },

    getIconStatus(item){

        if(item[this.periodActual] == null || item[this.periodActual] == 0){
            return this.tags[0];
        }else if(item.balance < 0){
            return this.tags[1];
        }else if(item.balance == 0){
            return this.tags[2];
        }else{
            return this.tags[3];
        }

    },

    getItemsByIndex(index){
        
        if(index == 0){
            return this.items.filter(i => (i[this.periodActual] == null || i[this.periodActual] == 0));
        }else if(index == 1){
            return this.items.filter(i => (i.balance < 0 && (i[this.periodActual] != null && i[this.periodActual] != 0)));
        }else if(index == 2){
            return this.items.filter(i => (i.balance == 0 && (i[this.periodActual] != null && i[this.periodActual] != 0)));
        }else{
            return  this.items.filter(i => i.balance > 0);
        }

    },

    showAvatar(item){
        if(item && item.url_avatar){
            this.selectedTeacher = item;
            this.showImage = true;
        }
    },

    openExtract(item){
        this.selectedItem = item.teacher;
        this.dialog = true;
    },

    customFilter (value, search, item) {
        
        if(!search || search.length <= 2 ){
            return true;
        }

        return item.teacher.name.toString().toLocaleUpperCase().indexOf(search.toString().toLocaleUpperCase()) !== -1;
         
      },

  }

}
